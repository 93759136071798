<template>
  <div>
    <!-- TABLE -->

    <table class="table table-hover table-items" style="background: white">
      <thead>
        <tr>
          <th>
            <button class="button btn btn-info btn-sm" v-tooltip="'Click to turn on sorting'" @click="sortingOn = !sortingOn">
              <i class="glyphicon glyphicon-move"></i>
            </button>
          </th>
          <th>Image</th>
          <th>Name</th>
          <th>Package Code</th>
        </tr>
      </thead>
      <!-- <tbody> -->
      <draggable v-model="packages" v-bind="dragOptions" @start="drag = true" @end="updateOrder" tag="tbody" :disabled="!sortingOn">
        <tr v-for="packageItem in packages" :key="packageItem.id" style="cursor: pointer" @click.prevent="selectPackage(packageItem.package_code)">
          <td>
            <i v-if="sortingOn" class="glyphicon glyphicon-move"></i>
          </td>
          <td>
            <div
              :style="`background-image: url(${$store.state.config.baseUrlApi}/api/private-files/package-images/${packageItem.package_image_path})`"
              style="width: 50px; height: 50px; background-size: cover; background-position: center"
              v-if="packageItem.package_image_path"
            ></div>
          </td>
          <td>
            {{ packageItem.package_name }}
          </td>

          <td>{{ packageItem.package_code }}</td>
        </tr>
      </draggable>
    </table>

    <div style="display: flex; justify-content: flex-end">
      <button class="btn btn-info" @click="addPackage()"><i class="glyphicon glyphicon-plus mr-1"> </i>Add new package</button>
    </div>
  </div>
</template>

<script>
import * as helpers from '@/helpers/functions.js';
import draggable from 'vuedraggable';
export default {
  components: {
    draggable,
  },
  data() {
    return {
      packages: null,
      drag: false,
      sortingOn: false,
    };
  },
  created() {
    this.getPackages();
  },
  computed: {
    parentTab() {
      return this.$parent.selectedTab;
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  watch: {
    parentTab(n) {
      if (n == 'packages') {
        this.getPackages();
      }
    },
  },
  methods: {
    getPackages() {
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('getPackages')
        .then((data) => {
          this.packages = data;
        })
        .catch((error) => helpers.displayError(error, 'getPackages', this))
        .finally(() => this.$store.commit('setTransLoader', false));
    },
    selectPackage(packageCode) {
      this.$router.push({ name: 'SelectedPackage', params: { packageCode: packageCode } });
    },
    updateOrder() {
      this.drag = false;
      let newlySortedPackages = this.packages.map((item, i) => {
        return {
          id: item.id,
          sort_order: i,
        };
      });
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('updatePackagesSortOrder', { packages: newlySortedPackages })
        .then((_) => {
          this.$snotify.success('Order updated', '', {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: 'rightTop',
          });
        })
        .catch((error) => helpers.displayErrorNoRetry('Error ordering, please try again', this))
        .finally(() => this.$store.commit('setTransLoader', false));
    },
    addPackage() {
      this.$router.push({ name: 'SelectedPackage' });
    },
  },
};
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>