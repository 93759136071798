<template>
  <div>
    <!-- TABLE -->
    <table class="table table-hover table-items" style="background: white">
      <thead>
        <tr>
          <th>Name</th>
          <th>Method</th>
          <th>Unit</th>
          <th>Price (AWG.)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id" @click="editItemModal(item)" style="cursor: pointer">
          <td>{{ item.name_analysis }}</td>
          <td>{{ item.method }}</td>
          <td>{{ item.measurement_unit ? item.measurement_unit.measurement_unit : 'NA' }}</td>
          <td>{{ item.price_awg }}</td>
        </tr>
      </tbody>
    </table>
    <div style="display: flex; justify-content: flex-end">
      <button class="btn btn-info" @click="addItemModal"><i class="glyphicon glyphicon-plus mr-1"> </i>Add analysis item</button>
    </div>

    <!-- MODAL -->
    <SelectedItemModal v-if="showModal" :modalId="modalId">
      <template v-slot:header> Analysis Item </template>
      <template v-slot:body>
        <form>
          <div class="form-group" :class="$v.selectedItem.name_analysis.$error ? 'has-error' : ''">
            <label for="exampleInputEmail1">Name</label>
            <input type="text" class="form-control" placeholder="Fill in name" v-model.trim="$v.selectedItem.name_analysis.$model" />
          </div>
          <div class="form-group" :class="$v.selectedItem.name_analysis.$error ? 'has-error' : ''">
            <label for="exampleInputEmail1">Method</label>
            <input type="text" class="form-control" placeholder="Fill in method" v-model.trim="$v.selectedItem.method.$model" />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Unit</label>
            <select name="cars" id="cars" class="form-control" v-model="selectedItem.measurement_unit_id">
              <option :value="null">none</option>
              <option :value="unit.id" v-for="unit in measurementUnits" :key="unit.id">{{ unit.measurement_unit }}</option>
            </select>
          </div>
          <div class="form-group" :class="$v.selectedItem.name_analysis.$error ? 'has-error' : ''">
            <label for="exampleInputEmail1">Price</label>
            <input type="number" class="form-control" placeholder="Fill in price" v-model.trim="$v.selectedItem.price_awg.$model" />
          </div>
        </form>

        <p class="text-danger" v-if="$v.selectedItem.$anyError" style="font-weight: 600; font-size: 14px">Please fill in all required fields.</p>
      </template>
      <template v-slot:actionButton>
        <button class="btn btn-danger pull-left" @click="deleteModal(selectedItem.id)" v-if="!addMode">Delete</button>
        <button class="btn btn-info" @click="addItem(selectedItem)" v-if="addMode">Add</button>
        <button class="btn btn-info" @click="saveItem(selectedItem)" v-if="!addMode">Save</button>
      </template>
    </SelectedItemModal>

    <!-- delete Modal -->
    <div id="delete-analysis-item-modal" class="modal fade" role="dialog">
      <div class="modal-dialog modal-sm">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Delete Item</h4>
          </div>
          <div class="modal-body">Are you sure you want to delete this item?</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="deleteItem(selectedIdToDelete)">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as helpers from '@/helpers/functions.js';
import SelectedItemModal from './SelectedItemModal.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
export default {
  mixins: [validationMixin],
  components: { SelectedItemModal },
  validations: {
    selectedItem: {
      name_analysis: { required },
      method: { required },
      price_awg: { required },
    },
  },
  destroyed: function () {
    helpers.removeModalFromBody('delete-analysis-item-modal');
    helpers.removeModalFromBody(this.modalId);
  },
  computed: {
    parentTab() {
      return this.$parent.selectedTab;
    },
    measurementUnits() {
      return this.$store.state.measurementUnits;
    },
  },
  watch: {
    parentTab(n) {
      if (n == 'analysis_items') {
        this.getAnalysisItems();
        this.$store.dispatch('getMeasurementUnits');
      }
    },
  },
  data() {
    return {
      items: null,
      selectedItem: null,
      showModal: false,
      addMode: false,
      modalId: 'analysisItemModal',
      selectedIdToDelete: null,
    };
  },
  methods: {
    getAnalysisItems() {
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('getAnalysisItems')
        .then((data) => {
          this.items = data;
        })
        .catch((error) => helpers.displayError(error, 'getAnalysisItems', this))
        .finally(() => this.$store.commit('setTransLoader', false));
    },
    addItemModal() {
      this.addMode = true;
      this.showModal = true;
      this.selectedItem = {
        name_analysis: '',
        method: '',
        measurement_unit_id: null,
        price_awg: null,
      };
      setTimeout(() => {
        jQuery('#' + this.modalId)
          .appendTo('body')
          .modal('show');
      }, 100);
    },
    editItemModal(item) {
      this.addMode = false;
      this.showModal = true;
      this.selectedItem = JSON.parse(JSON.stringify(item));
      setTimeout(() => {
        jQuery('#' + this.modalId)
          .appendTo('body')
          .modal('show');
      }, 100);
    },
    saveItem(item) {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('editAnalysisItem', item)
        .then((data) => {
          this.items = data;
          jQuery('#' + this.modalId)
            .appendTo('body')
            .modal('hide');
          this.$snotify.success(`Item successfully edited.`, '', {
            timeout: 3000,
            showProgressBar: false,
            pauseOnHover: false,
            position: 'rightTop',
          });
        })
        .catch((error) => helpers.displayErrorNoRetry(error, this))
        .finally(() => this.$store.commit('setTransLoader', false));
    },
    addItem(item) {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('addAnalysisItem', item)
        .then((data) => {
          this.items = data;
          jQuery('#' + this.modalId)
            .appendTo('body')
            .modal('hide');
          this.$snotify.success(`Item successfully added.`, '', {
            timeout: 3000,
            showProgressBar: false,
            pauseOnHover: false,
            position: 'rightTop',
          });
        })
        .catch((error) => helpers.displayErrorNoRetry(error, this))
        .finally(() => this.$store.commit('setTransLoader', false));
    },
    deleteModal(id) {
      this.selectedIdToDelete = id;
      jQuery('#delete-analysis-item-modal').appendTo('body').modal('show');
    },
    deleteItem(id) {
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('deleteAnalysisItem', id)
        .then((data) => {
          jQuery('#' + this.modalId)
            .appendTo('body')
            .modal('hide');
          if (!data.message) {
            this.items = data;
            this.$snotify.success('Analysis item deleted', '', {
              timeout: 3000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              position: 'rightTop',
            });
          } else {
            this.$snotify.error(data.message, '', {
              timeout: 3000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              position: 'rightTop',
            });
          }
        })
        .catch((error) => helpers.displayErrorNoRetry(error, this))
        .finally(() => {
          this.$store.commit('setTransLoader', false);
          this.selectedIdToDelete = null;
        });
    },
  },
};
</script>
