<template>
  <div id="labpackages-page">
    <Navigation></Navigation>
    <div class="dashboard-container-right">
      <h2 class="title mt-4">Lab Packages</h2>
      <hr />
      <div>
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" @click="selectedTab = 'packages'" class="active">
            <a href="#packages" aria-controls="messages" role="tab" data-toggle="tab">Packages</a>
          </li>
          <li role="presentation" @click="selectedTab = 'analysis_items'">
            <a href="#analysis_items" aria-controls="profile" role="tab" data-toggle="tab">Analysis Items</a>
          </li>
          <li role="presentation" @click="selectedTab = 'units'">
            <a href="#untis" aria-controls="home" role="tab" data-toggle="tab">Measurement Units</a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div role="tabpanel" class="tab-pane active" id="packages">
            <LabPackages />
          </div>

          <div role="tabpanel" class="tab-pane" id="analysis_items">
            <AnalysisItems />
          </div>
          <div role="tabpanel" class="tab-pane" id="untis">
            <MeasurementUnits />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/generic/Navigation.vue';
import MeasurementUnits from './components/MeasurementUnits.vue';
import AnalysisItems from './components/AnalysisItems.vue';
import LabPackages from './components/LabPackages.vue';
export default {
  data() {
    return {
      selectedTab: 'packages'
    };
  },
  components: { Navigation, MeasurementUnits, AnalysisItems, LabPackages }
};
</script>

<style lang="scss">
#labpackages-page {
  .nav-tabs {
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
