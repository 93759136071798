<template>
  <!-- Modal -->
  <div :id="modalId" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">
            <slot name="header"></slot>
          </h4>
        </div>
        <div class="modal-body"><slot name="body"></slot></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          <slot name="actionButton"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['modalId'],
};
</script>

<style>
</style>