<template>
  <div>
    <table class="table table-hover" style="background: white">
      <thead>
        <tr>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="unit in units" :key="unit.id" style="cursor: pointer" @click="editUnitModal(unit)">
          <td>{{ unit.measurement_unit }}</td>
        </tr>
      </tbody>
    </table>
    <div style="display: flex; justify-content: flex-end">
      <button class="btn btn-info" @click="addUnitModal"><i class="glyphicon glyphicon-plus mr-1"> </i>Add measurement unit</button>
    </div>

    <SelectedItemModal v-if="showModal" :modalId="modalId">
      <template v-slot:header> Analysis Item </template>
      <template v-slot:body>
        <form>
          <div class="form-group" :class="$v.selectedItem.measurement_unit.$error ? 'has-error' : ''">
            <label for="exampleInputEmail1">Name</label>
            <input type="text" class="form-control" placeholder="Fill in name" v-model.trim="$v.selectedItem.measurement_unit.$model" />
          </div>
        </form>

        <p class="text-danger" v-if="$v.selectedItem.$anyError" style="font-weight: 600; font-size: 14px">Please fill in all required fields.</p>
      </template>
      <template v-slot:actionButton>
        <button class="btn btn-danger pull-left" @click="deleteModal(selectedItem.id)" v-if="!addMode">Delete</button>
        <button class="btn btn-info" @click="addUnit(selectedItem)" v-if="addMode">Add</button>
        <button class="btn btn-info" @click="editUnit(selectedItem)" v-if="!addMode">Save</button>
      </template>
    </SelectedItemModal>

    <!-- delete Modal -->
    <div id="delete-measurement-unit-modal" class="modal fade" role="dialog">
      <div class="modal-dialog modal-sm">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Delete unit</h4>
          </div>
          <div class="modal-body">Are you sure you want to delete this item?</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="deleteUnit(selectedIdToDelete)">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as helpers from '@/helpers/functions.js';
import SelectedItemModal from './SelectedItemModal.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  components: { SelectedItemModal },
  validations: {
    selectedItem: {
      measurement_unit: { required },
    },
  },
  computed: {
    parentTab() {
      return this.$parent.selectedTab;
    },
  },
  watch: {
    parentTab(n) {
      if (n == 'units') {
        this.getUnits();
      }
    },
  },
  data() {
    return {
      units: null,
      selectedIdToDelete: null,
      addMode: false,
      showModal: false,
      selectedItem: null,
      modalId: 'measurementUnitModal',
    };
  },
  created() {
    //this.getUnits();
  },
  destroyed: function () {
    helpers.removeModalFromBody('delete-measurement-unit-modal');
    helpers.removeModalFromBody(this.modalId);
  },
  methods: {
    getUnits() {
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('getMeasurementUnits')
        .then((data) => {
          this.units = data.map((unit) => {
            return {
              ...unit,
              edit: false,
            };
          });
        })
        .catch((error) => helpers.displayError(error, 'getUnits', this))
        .finally(() => this.$store.commit('setTransLoader', false));
    },
    deleteModal(id) {
      this.selectedIdToDelete = id;
      jQuery('#delete-measurement-unit-modal').appendTo('body').modal('show');
    },
    deleteUnit(id) {
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('deleteMeasurementUnit', id)
        .then((data) => {
          jQuery('#' + this.modalId)
            .appendTo('body')
            .modal('hide');
          if (!data.message) {
            this.units = data;
            this.$snotify.success('Unit deleted', '', {
              timeout: 3000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              position: 'rightTop',
            });
          } else {
            this.$snotify.error(data.message, '', {
              timeout: 3000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              position: 'rightTop',
            });
          }
        })
        .catch((error) => helpers.displayErrorNoRetry(error, this))
        .finally(() => {
          this.$store.commit('setTransLoader', false);
          this.selectedIdToDelete = null;
        });
    },
    addUnitModal() {
      this.addMode = true;
      this.showModal = true;
      this.selectedItem = {
        measurement_unit: '',
      };
      setTimeout(() => {
        jQuery('#' + this.modalId)
          .appendTo('body')
          .modal('show');
      }, 100);
    },
    editUnitModal(unit) {
      this.addMode = false;
      this.showModal = true;
      this.selectedItem = JSON.parse(JSON.stringify(unit));
      setTimeout(() => {
        jQuery('#' + this.modalId)
          .appendTo('body')
          .modal('show');
      }, 100);
    },
    addUnit(unit) {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('addMeasurementUnit', unit)
        .then((data) => {
          this.units = data;
          jQuery('#' + this.modalId)
            .appendTo('body')
            .modal('hide');
          this.$snotify.success(`Item successfully added.`, '', {
            timeout: 3000,
            showProgressBar: false,
            pauseOnHover: false,
            position: 'rightTop',
          });
        })
        .catch((error) => helpers.displayErrorNoRetry(error, this))
        .finally(() => this.$store.commit('setTransLoader', false));
    },
    editUnit(unit) {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('editMeasurementUnit', unit)
        .then((data) => {
          this.units = data;
          jQuery('#' + this.modalId)
            .appendTo('body')
            .modal('hide');
          this.$snotify.success(`Item successfully edited.`, '', {
            timeout: 3000,
            showProgressBar: false,
            pauseOnHover: false,
            position: 'rightTop',
          });
        })
        .catch((error) => helpers.displayErrorNoRetry(error, this))
        .finally(() => this.$store.commit('setTransLoader', false));
    },
  },
};
</script>

<style>
</style>